<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="Categories !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="DeleteCategory"
      >
        <span>آیا از حذف کردن این دسته بندی اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          centered
          ok-only
          ok-title="ثبت"
          title="بروزرسانی دسته بندی"
          @ok.preventDefault()="UpdateCategory"
      >
        <b-row v-if="SelectedCategory!==null">
          <b-col cols="12">
            <b-form-group>
              <label>نام دسته بندی</label>
              <b-form-input
                  v-model="SelectedCategory.name"
                  placeholder="نام دسته بندی"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h2>سئو</h2>

          </b-col>
          <b-col class="" md="6">
            <b-form-group
                class=""
                label="عنوان"
                label-for="blog-edit-title"
            >
              <b-form-input
                  id="blog-edit-seoTitle2"
                  v-model="SelectedCategory.seoTitle"
              />
            </b-form-group>
            <span :class="{'text-danger':SelectedCategory.seoTitle.length>60}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                newCategory.seoTitle.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                    class=""
                    label="آدرس"
                    label-for="blog-edit-seourl"
                >
                  <b-form-input
                      id="blog-edit-seourl2"
                      ref="myInput"
                      v-model="allCharactersToDashUpdate"
                      v-on:focus="$event.target.select()"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>
          <!-- Field:  description -->
          <b-col class="mt-2" cols="12">
            <b-form-group
                class="mb-2"
                label="توضیحات"
                label-for="blog-content"
            >
              <b-form-textarea
                  v-model="SelectedCategory.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span :class="{'text-danger':SelectedCategory.seoDescription.length>160}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                SelectedCategory.seoDescription.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
          id="modal-Add"
          centered
          ok-only
          ok-title="ثبت"
          title="افزودن دسته بندی (فرعی)"
          @ok.preventDefault()="AddCategory"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label>نام دسته بندی</label>
              <b-form-input
                  v-model="newCategory.name"
                  placeholder="نام دسته بندی"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h2>سئو</h2>
          </b-col>
          <b-col class="" md="6">
            <b-form-group
                class=""
                label="عنوان"
                label-for="blog-edit-title"
            >
              <b-form-input
                  id="blog-edit-seoTitle"
                  v-model="newCategory.seoTitle"
              />
            </b-form-group>
            <span :class="{'text-danger':newCategory.seoTitle.length>60}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                newCategory.seoTitle.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="12">
                <b-form-group
                    class=""
                    label="آدرس"
                    label-for="blog-edit-seourl"
                >
                  <b-form-input
                      id="blog-edit-seourl"
                      ref="myInput"
                      v-model="allCharactersToDash"
                      v-on:focus="$event.target.select()"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-col>
          <!-- Field:  description -->
          <b-col class="mt-2" cols="12">
            <b-form-group
                class="mb-2"
                label="توضیحات"
                label-for="blog-content"
            >
              <b-form-textarea
                  v-model="newCategory.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span :class="{'text-danger':newCategory.seoDescription.length>160}"
                  class="">تعداد کاراکترهای مجاز <small>{{
                newCategory.seoDescription.length
              }}</small><small
                class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>
        </b-row>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="8" md="12">
              <b-form-input
                  id="basicInput"
                  v-model="search"
                  placeholder="جستجو بر اساس نام دسته بندی"
              />
            </b-col>
            <b-col class="my-2" lg="2" md="12">
              <b-button
                class="w-100"
                  v-b-modal.modal-Add
                  variant="success"

              >
                افزودن دسته بندی جدید
              </b-button>
            </b-col>
            <b-col class="my-2" cols="12" lg="2">
              <clear-sorts-btn @clear="sortBy=''"></clear-sorts-btn>
            </b-col>

          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="Categories"
            bordered
            :sort-by.sync="sortBy"
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >


          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedCategory(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedCategory(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>

            </div>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BFormTextarea, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  CreateProductCategory,
  UpdateProductCategory,
  DeleteProductCategory,
  GetAllProductCategory
} from "@/libs/Api/productCategory";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  title: 'لیست دسته بندی (فرعی) محصولات  - پنل ادمین انزا مد',
  name: 'ProductsCategoryId',
  data() {
    return {
      Categories: null,
      totalCount: null,
      showOverlay: false,
      parentId: this.$route.params.id,
      ProductCategories: null,
      sortBy:'',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'productCategoryId',
          label: 'شناسه',
          sortable:true
        },
        {
          key: 'name',
          label: 'نام',
          sortable:true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

        // {
        //   key: 'SEO',
        //   label: 'SEO'
        // },
      ],
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      newCategory: {
        seourl: '',
        seoTitle: '',
        seoDescription: '',
        fileData: null,
        name: '',
        parentId: 0,
      },
      search: '',
      SelectedCategory: null,
      SelectedCategoryId: 0

    }
  },
  async created() {
    await this.GetAllCategory()
  },
  components: {
    ClearSortsBtn,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BFormTextarea,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {
    search: function () {
      this.GetAllCategory()
    },
    currentPage: function () {
      this.GetAllCategory()
    }
  },
  computed: {
    allCharactersToDashUpdate: {
      get: function () {
        return this.SelectedCategory.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.SelectedCategory.seourl = newValue
          // Remove all characters that are NOT number
          this.SelectedCategory.seourl = this.SelectedCategory.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.SelectedCategory.seourl = this.SelectedCategory.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.SelectedCategory.seourl === "") {
          this.SelectedCategory.seourl = null;
        }
      },
    },
    allCharactersToDash: {
      get: function () {
        return this.newCategory.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.newCategory.seourl = newValue
          // Remove all characters that are NOT number
          this.newCategory.seourl = this.newCategory.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.newCategory.seourl = this.newCategory.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.newCategory.seourl === "") {
          this.newCategory.seourl = null;
        }
      },
    },
  },
  methods: {
    async UpdateCategory() {
      let _this = this;
      _this.showOverlay = true;
      let updateProductCategory = new UpdateProductCategory(_this)
      let data = {
        productCategoryId: this.SelectedCategory.productCategoryId,
        name: this.SelectedCategory.name,
        parentId: this.SelectedCategory.parentId,
        svg: this.SelectedCategory.svg,
        seoTitle: this.SelectedCategory.seoTitle,
        seoDescription: this.SelectedCategory.seoDescription,
        seourl: this.SelectedCategory.seourl,
        fileData: this.SelectedCategory.fileData,
      }
      updateProductCategory.setData(data)
      await updateProductCategory.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی بروزرسانی شد`,
          },
        })
        _this.SelectedCategory = null
        _this.GetAllCategory();
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async AddCategory() {
      let _this = this
      _this.showOverlay = true;
      if (_this.newCategory.name !== '' && _this.newCategory.seourl !== '' && _this.newCategory.seoTitle !== '' && _this.newCategory.seoDescription !== '') {
        let createProductCategory = new CreateProductCategory(_this)
        let data = {
          name: _this.newCategory.name,
          seourl: _this.newCategory.seourl,
          seoTitle: _this.newCategory.seoTitle,
          seoDescription: _this.newCategory.seoDescription,
          parentId: _this.parentId
        }
        createProductCategory.setData(data)
        await createProductCategory.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `دسته بندی ساخته  شد`,
            },
          })
          _this.GetAllCategory();
          _this.CategoryName = ''
          _this.showOverlay = false;
        }, function (error) {
          console.log(error)
        })
      } else {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `لطفا فیلد هارا پر  کنید`,
          },
        })
      }


    },
    async DeleteCategory() {
      let _this = this
      _this.showOverlay = true;
      let deleteProductCategory = new DeleteProductCategory(_this)
      deleteProductCategory.setParams({id: this.SelectedCategory.productCategoryId})
      await deleteProductCategory.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `دسته بندی  حذف شد.`,
          },
        })
        _this.GetAllCategory();
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    SetSelectedCategory(item) {
      this.SelectedCategory = JSON.parse(JSON.stringify(item))
    },
    async GetAllCategory() {
      let _this = this;
      _this.showOverlay = true;
      let getAllProductCategory = new GetAllProductCategory(_this)
      let data = {
        pageNumber: _this.currentPage,
        search: _this.search,
        count: 10,
        parentId: _this.parentId
      }
      getAllProductCategory.setParams(data)
      await getAllProductCategory.fetch(function (content) {
        _this.Categories = content.productCategories
        _this.totalCount = content.categoriesCount
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>

</style>
